init_action_reveal = ->
  $('.reveal.ask-for-action').each((index,reveal) ->
    if $('.reveal.ask-for-action').prop('initialized')
      return
    $('.reveal.ask-for-action').prop('initialized', true)

    $(reveal).find('.deny-action').on('click', (button) ->
      $(reveal).foundation('close')
    )
    $(reveal).find('.call-hidden-link').on('click', (button) ->
      link_id = $(this).data('link-id')
      $("##{link_id}").find('a').click()
      $(reveal).foundation('close')
    )

    # don't submit form if field is empty
    $(reveal).find('input[type="submit"][data-close-if-empty]').on('click', (event) ->
      form_field_id = $(this).data('form-field')
      if !form_field_id
        return
      form = $(this).closest('form')
      form_field = $(form).find(form_field_id)
      if !$(form_field).val()
        event.preventDefault()
        $(reveal).foundation('close')
    )

    # select-records-reveal
    # applied: projects/edit (select project-team)
    if ($(reveal).hasClass('select-records-reveal'))
      $(reveal).on('open.zf.reveal', (event) ->
        input = $(event.target).find('#search-input')
        input.val('')
        f = $(event.target).find('form')
        #input.on('keyup', console.log('keyup => ' + input.val()));
        input.on('keyup', (event) ->
          submit_btn = $('#srr-submit-button')
          submit_btn.click()
        )
        tb = $(reveal).find('tbody').html('')
        input.focus()
        $('#select_records_reveal_submit').prop('disabled', true)
      )
      $(reveal).on('closed.zf.reveal', (event) ->
        form = document.querySelector('form.search-input');
        form.reset()
      )
  )

$ ->
  init_action_reveal()

$(document).on 'turbolinks:load', ->
  init_action_reveal()