init_guarantee_reserve = ->
  $('input[name="project[guarantee_reserve]"]').off().on('change', (event) ->
    input_guarantee_reserve = $(event.target)
    input_reason_no_reserve = $('input[name="project[reason_no_reserve]"]')
    if input_guarantee_reserve.val() == 'true'
      input_reason_no_reserve.val('')
      input_reason_no_reserve.attr('disabled', 'disabled')
    else
      input_reason_no_reserve.attr('disabled', false)
  )

# ensure mean or single rates exclusive, refs #12455
exclusive_rates = ->
  $('.mean input').on 'change', (event) ->
    that = $(this)
    if that.val() > 0
      $('.single input').val(0.0)
    return

  $('.single input').on 'change', (event) ->
    if $(this).val() > 0
      $('.mean input').val(0.0)
    return
  return

on_change_project_definite_open = (el) ->
  lab = $(el.target).find(':selected').text()
  number = $(el.target).val()
  rev = $('#confirm-definite-reveal')
  url = rev.find('a.confirm_button').attr("href")
  url_new = url.split('?')[0] + '?definite_open=' + number
  rev.find('a.confirm_button').attr("href", url_new)
  rev.foundation('open')

init_project_summary = () ->
  e = $('#contents-wrapper.projects.summary')
  if e
    sel = $('select#definite_open')
    sel.on 'change', (sel) ->
      on_change_project_definite_open(sel)

$ ->
  init_guarantee_reserve()
  exclusive_rates()

$(document).on 'turbolinks:load', ->
  init_guarantee_reserve()
  exclusive_rates()
  init_project_summary()