// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

Rails.start()
Turbolinks.start()
ActiveStorage.start()

// import the application stylesheets and images
import 'stylesheets/application.scss'
const images = require.context('../images', true)

// import foundation and font-awesome
import 'foundation-sites';
import "@fortawesome/fontawesome-free/js/all";

// jquery and jquery-ui
require("jquery")
require("jquery-ui")
import $ from 'jquery';
global.$ = jQuery;
import 'jquery-ui-dist/jquery-ui.css'

////////////////////////////////////////
// add javascript libraries
////////////////////////////////////////
// ckeditor + cocoon + jqtree for pages
require('@ckeditor/ckeditor5-build-classic')
import 'src/init_ckeditor';
require('@nathanvda/cocoon')
require('jqtree')
require('jqtree/jqtree.css')
//require('echarts')



// zebra datepicker
require('zebra_datepicker');
require('zebra_datepicker/dist/css/metallic/zebra_datepicker.css');
import 'src/datepicker';

// custom javascript (sorted alphabetically)
import 'src/areYouSure';
import 'src/circle_graph';
import 'src/confirm-with-reveal';
import 'src/cocoon_callbacks'
import 'src/dialog';
import 'src/forms';
import 'src/menu';
import 'src/pages';
import 'src/components/workload_planning'
import 'src/components/month_end_closing'
import 'src/components/projects';
import 'src/components/sub_contractors';

// import select 2
import 'select2/dist/js/select2.full';
import 'select2/dist/css/select2.css';
import "src/select2_config";

// initialize the page
$(document).on('turbolinks:load', function() {
    onPageLoad();
});
$(document).ready(function () {
    onPageLoad();
});
$(document).confirmWithReveal();

function onPageLoad() {
    // init foundation
    $(document).foundation();
    $('form').areYouSure( {'message': gon.ays} );
}

