import * as echarts from 'echarts'

init_action_chart = (e, year, series) ->
  myChart = echarts.init(e);
  #console.log('chart-init, series => ' + JSON.stringify(series))
  option = {
    title: {
      text: year,
      textStyle: {
        fontSize: '1.2rem'
      }
    },
    tooltip: {
      trigger: 'axis'
    },
    legend: {
      data: ['geplant', 'verbraucht'],
      itemHeight: 0,
      show: false
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true
    },
    toolbox: {
      feature: {
        saveAsImage: {}
      }
    },
    xAxis: {
      type: 'category',
      boundaryGap: false,
      data: gon.x_axis_labels
    },
    yAxis: {
      type: 'value',
      show: true
    },
    series: series
  };
  myChart.setOption(option)


on_scroll_chart = (element) ->
  left = $(element).scrollLeft()
  $('#hours-chart-container').css('margin-left', - left + 'px')
  console.log('scrolling' + $(element).scrollLeft())

init_action_charts = ->
  wrapper = document.getElementById('workload-reports-wrapper')
  if wrapper
    if $(wrapper).prop('initialized')
      return
    $(wrapper).prop('initialized', true)

    # ---------------    PLANNED HOURS    ---------------

    wrap = document.getElementById('hours-chart-wrapper')
    series = [{
      name: 'geplant',
      type: 'line',
      stack: 'a',
      data: gon.planned_hours,
      lineStyle: {
        color: '#000'
      },
      itemStyle: {
        color: '#000'
      }
    },
      {
        name: 'verbraucht',
        type: 'line',
        stack: 'b',
        data: gon.reported_hours,
        lineStyle: {
          color: '#E96F17'
        },
        itemStyle: {
          color: '#E96F17'
        }
      }]
    init_action_chart wrap, '', series


    # ---------------    PLANNED COSTS    ---------------


    wrap2 = document.getElementById('costs-chart-wrapper')
    series = [{
      name: 'geplant',
      type: 'line',
      stack: 'a',
      data: gon.planned_costs,
      lineStyle: {
        color: '#000'
      },
      itemStyle: {
        color: '#000'
      }
    },
      {
        name: 'verbraucht',
        type: 'line',
        stack: 'b',
        data: gon.reported_costs,
        lineStyle: {
          color: '#E96F17'
        },
        itemStyle: {
          color: '#E96F17'
        }
      }]
    init_action_chart wrap2, '', series

    # ----------------      SCROLLBAR     -----------------

    scroller = document.getElementById('chart-scroller')
    console.log('inited')
    $(scroller).on 'scroll', ->
      on_scroll_chart(scroller)


$ ->
  init_action_charts()

$(document).on 'turbolinks:load', ->
  init_action_charts()