init_side_menu = ->
  $('.side-menu-close').on('click', () ->
    $('.side-bar').addClass('hide')
    $('.side-menu-open').removeClass('hide')
    $('.main-container').removeClass('medium-9 large-10')
    # trigger resize for foundation max-width on sticky elements
    $('.header').trigger('resize')
  )


  $('.side-menu-open').on('click', () ->
    $('.side-bar').removeClass('hide')
    $('.side-menu-open').addClass('hide')
    $('.main-container').addClass('medium-9 large-10')
    # trigger resize for foundation max-width on sticky elements
    $('.header').trigger('resize')
  )

$ ->
  init_side_menu()

$(document).on 'turbolinks:load', ->
  init_side_menu()