init_circle_graph = ->
  $('[data-circle-graph]').each ->
    $graph = $(this)
    percent = parseFloat($graph.data('percent'))
    deg = 360 * percent / 100
    if percent > 50
      $graph.addClass 'gt-50'
    $graph.find('.circle-graph-progress-fill').css 'transform', 'rotate(' + deg + 'deg)'
    return

$ ->
  init_circle_graph()

$(document).on 'turbolinks:load', ->
  init_circle_graph()