ckeditor_load = ->
  $('textarea.ckeditor').each(() ->
    unless $(this).data('initialized')
      $(this).data('initialized', true)
      ClassicEditor.create(this, {
        toolbar: [
          'selectAll', 'undo', 'redo', '|',
          'bold', 'italic', 'underline', 'strikethrough', 'code','subscript', 'superscript',
          'blockQuote', 'ckfinder',
          "imageTextAlternative", "imageUpload", "heading", "imageStyle:full",
          "imageStyle:side", "indent", "outdent", "link", "numberedList", "bulletedList",
          "mediaEmbed", "insertTable", "tableColumn", "tableRow", "mergeTableCells"
        ]

      }).then((editor) ->
        console.log(Array.from( editor.ui.componentFactory.names() ))
      )

      # { name: 'document', items: [ 'Source', '-', 'Save' ] },
      # { name: 'editing', items: [ '-', 'SelectAll', '-', 'Scayt' ] },
      # '/',
      # { name: 'basicstyles', items: [ 'Bold', 'Italic', 'Underline', 'Strike', 'Subscript', 'Superscript', '-', 'CopyFormatting', 'RemoveFormat' ] },
      # { name: 'paragraph', items: [ 'NumberedList', 'BulletedList', '-', 'Outdent', 'Indent', '-', 'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock' ] },
      # { name: 'links', items: [ 'Link', 'Unlink', 'Anchor' ] },
      # { name: 'insert', items: [ 'Image', 'Table', 'HorizontalRule', 'SpecialChar' ] },
      # '/',
      # { name: 'styles', items: [ 'Styles', 'Format', 'Font', 'FontSize' ] },
      # { name: 'colors', items: [ 'TextColor', 'BGColor' ] },
      # { name: 'tools', items: [ 'Maximize', 'ShowBlocks' ] },
      # { name: 'about', items: [ 'About' ] }
  )

$(document).on 'turbolinks:load', ->
  ckeditor_load()

$(document).ready(() ->
  ckeditor_load()
)

$(document).on 'init_ckeditor', ->
  ckeditor_load()