cocoon_confirm = (e, insertedItem, originalEvent) ->
  confirmation = confirm('Das Teammitglied ' + insertedItem[0].querySelector('.initials').innerHTML + ' ' + insertedItem[0].querySelector('.name').innerHTML + " aus dem Projekt entfernen?");
  if(confirmation == false)
    e.preventDefault();

# workload
cocoon_after_add_workload = ->
  $('#cocoon-workload-container').find('.row.is-new').each((i, e) ->
    $(e).removeClass('is-new')

    # find cocoon-id
    cocoon_id = $(e).find('.id-column').first().prop('name').match(/[0-9]+/)[0]

    # set id to row-element
    r_id = $(e).prop('id')
    if (r_id.match(/\[index\]/))
      new_r_id = r_id.replace('[index]', '[' + cocoon_id + ']')
      $(e).prop('id', new_r_id)

    # replace name
    $(e).find('input').each((ind, ele) ->
      name = $(ele).prop('name')
      if (name.match(/\[index\]/))
        name2 = name.replace('[index]', '[' + cocoon_id + ']')
        $(ele).prop('name', name2)
    )
    $(e).find('a.remove_fields').each((ind, ele) ->
      id = $(ele).prop('id')
      if (id.match(/\[index\]/))
        new_id = id.replace('[index]', '[' + cocoon_id + ']')
        $(ele).prop('id', new_id)
    )
    $(e).find('.link_to_remove_action').on('click', (e) -> delete_row_show_reveal(e))
  )

init_action_cocoon = ->

# project/edit
  $('#staff-members-wrapper,#staff-members-wrapper-2')
    .off('cocoon:before-remove', cocoon_confirm)
    .on('cocoon:before-remove', cocoon_confirm)

  # workload
  $('#cocoon-workload-container')
    .off('cocoon:after-insert', cocoon_after_add_workload)
    .on('cocoon:after-insert', cocoon_after_add_workload)

  # confirm with reveal on link_to_remove
  $('.link_to_remove_action').off('click', (e) -> delete_row_show_reveal(e))
  $('.link_to_remove_action').on('click', (e) -> delete_row_show_reveal(e))


delete_row_show_reveal = (e) ->
  reveal = $('#cocoon-remove-association-reveal')
  row = $(e.target).closest('.row.nested-fields')
  row_id = row.prop('id')
  row_name = row.data('row-name')
  cost_center = row.find('select.cost_center')
  if cost_center.length > 0
    phase = cost_center.find('option:selected').html()
    if phase
      phase = phase.replaceAll(/(&nbsp;)+/g,'&nbsp;')
    user_name = row.find('select.user_id option:selected').html()
    if user_name
      user_name = user_name.replaceAll(/(&nbsp;)+/g,'&nbsp;')
    func = row.find('select.available_staff_id option:selected').html()
    if func
      func = func.replaceAll(/(&nbsp;)+/g,'&nbsp;')
    row_name = phase + ' | ' + user_name + ' | ' + func
  body = [
    '<p>',
    gon.cocoon_reveal_delete_row_t1,
    '<br><b>', row_name, '</b><br>'
    gon.cocoon_reveal_delete_row_t2,
    '</p>'
    '<p><i>',
    gon.cocoon_reveal_delete_row_t3,
    '</i></p>'
  ].join('')
  reveal.find('p.body').html(body)

  reveal.find('#confirm_button').on('click', ->
    delete_row_confirmed_action(reveal, row_id))
  reveal.foundation('open')


delete_row_confirmed_action = (reveal, row) ->
  $(reveal).foundation('close')
  btn = $('#' + row).find('.link-to-remove-button')
  btn.click()


$ ->
  init_action_cocoon()

$(document).on 'turbolinks:load', ->
  init_action_cocoon()