# Place all the behaviors and hooks related to the matching controller here.
# All this logic will automatically be available in application.js.
# You can use CoffeeScript in this file: http://coffeescript.org/




# file upload button
init_file_upload_btn = ->
  if $('.file-upload-container').length > 0
    $('.file-upload-container input[type="file"]').on('change', (event) ->
      file_input = $(event.target)
      input_container = file_input.closest('.file-upload-container')
      selected_file_label = input_container.find('.selected-file-label')
      if file_input.val()
        selected_file_label.text(file_input.val().split('\\').pop())
      else
        selected_file_label.text(selected_file_label.data('label'))
    )

init_form_cancel_button = ->
  if $('.cancel-form-button').length > 0
    $('.cancel-form-button').on('click', (event) ->
      forms = document.getElementsByTagName('form');
      for f of forms
        forms[f].reset()
    )

$ ->
  init_file_upload_btn()
  init_form_cancel_button()

$(document).on 'turbolinks:load', ->
  init_file_upload_btn()
  init_form_cancel_button()

