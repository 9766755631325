init_sia_inputs = ->
  $('input[name*="sub_contractor[release_status"]').off().on('change', (event) ->
    my_input = $(event.target)
    other_input = $("input[name='#{my_input.data('input-to-disable')}'")

    if my_input.is(':checked')
      other_input.attr('disabled', false)
    else
      other_input.val('0.0')
      other_input.attr('disabled', 'disabled')
  )

$ ->
  init_sia_inputs()

$(document).on 'turbolinks:load', ->
  init_sia_inputs()